package com.picme

import com.lightningkite.kiteui.*
import com.lightningkite.kiteui.exceptions.ExceptionHandler
import com.lightningkite.kiteui.models.*
import com.lightningkite.kiteui.navigation.*
import com.lightningkite.kiteui.reactive.*
import com.lightningkite.kiteui.views.*
import com.lightningkite.kiteui.views.direct.*
import com.lightningkite.kiteui.views.l2.navigatorView
import com.lightningkite.kiteui.views.l2.navigatorViewDialog
import com.lightningkite.kiteui.views.l2.overlayStack
import com.picme.components.*
import com.picme.layout.sidePanel
import com.picme.layout.toolbar
import com.picme.sdk2.Session
import com.picme.sdk2.prepareUploadConditionTypeFields
import com.picme.sdk2.toSafeEncoded
import com.picme.sdk2.unauthApi
import com.picme.views.*
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.launch

interface UnauthScreen
interface IncludeTopBar
interface NoTopBar


private val loadingInitial = Property(true)

val requireVerifiedAccounts = Platform.current != Platform.iOS && Platform.current != Platform.Android

fun ViewWriter.app(main: ScreenNavigator, dialog: ScreenNavigator) {
    prepareUploadConditionTypeFields()
    appMaterialTheme.onNext - customAppBase(main, dialog) {
        this += object : ExceptionHandler {
            override val priority: Float
                get() = 10f

            override fun handle(view: RView, working: Boolean, exception: Exception): (() -> Unit)? {
                if (exception is ConnectionException && exception.cause is CancellationException) return {}
                else return null
            }

        }
        reactiveSuspending {
            try {
                val token = sessionRefreshToken()
                if (token != null) {
                    val res = unauthApi.awaitOnce().authenticationHandler.refreshUserAuthentication(token)
                    val newSession = Session(
                        unauthApi.awaitOnce(),
                        res.successfulAuthentication!!.authenticated,
                        res.successfulAuthentication.authenticatedUser
                    )
                    newSession.collection2.listCollectionsLive().all.awaitOnce()
                    session set newSession
                } else {
                    session set null
                }
            } catch (e: Exception) {
                e.printStackTrace2()
                session set null
                logout()
            } finally {
                loadingInitial.set(false)
            }
        }
        reactiveSuspending {
            if (loadingInitial()) return@reactiveSuspending
            val s = screenNavigator.currentScreen()

            val notVerified =
                (session() == null || (requireVerifiedAccounts && session()?.isVerifiedAccount() == false))

            if (s !is UnauthScreen && notVerified) {
                screenNavigator.navigate(
                    if (requireVerifiedAccounts) LoginOrSignUp() else CollectionLanding
                )
            }
            if (s is LoginOrSignUp && session()?.isVerifiedAccount() == true) {
                navigateToCollOrLanding()
            }
        }

        reactiveScope {
            if (logoutTrigger()) {
                launchGlobal {
                    removeCaches()
                    session.value = null
                    sessionRefreshToken set null
                    currentCollection set null
                    acceptingInvite set null
                    dialogScreenNavigator.dismiss()
                    screenNavigator.reset(LoginOrSignUp())
                    logoutTrigger set false
                }
            }
        }

        fullScreenLoading(loadingInitial)

        col {
            ::exists { !loadingInitial() }
            spacing = 0.px
            expanding - row {
                spacing = 1.dp
                sidePanel()
                expanding - col {
                    spacing = 0.dp
                    toolbar()
                    expanding - navigatorView(this.screenNavigator)
                }
            }
        }
    }
}


fun ViewWriter.customAppBase(main: ScreenNavigator, dialog: ScreenNavigator, mainLayout: ContainingView.() -> Unit) {
    stack {
        spacing = 0.dp
        mainScreenNavigator = main
        dialog.let {
            dialogScreenNavigator = it
        }
        main.bindToPlatform(context)
        screenNavigator = main
        overlayStack = this
        mainLayout()
        dialog.let {
            navigatorViewDialog() in ThemeDerivation { it.withoutBack }.onNext
        }
        toast()
    }
}


@Routable("/")
class LandingScreen : Screen, UnauthScreen {
    override fun ViewWriter.render() {
        suspend fun handleWebFlow(isLoggedIn: Boolean) {
            if (isLoggedIn) {
                val firstCollection = session()?.collection2?.listCollectionsLive()?.all()?.firstOrNull()
                if (firstCollection != null) {
                    screenNavigator.replace(CollectionDetail(firstCollection.collection.collectionId.raw.toSafeEncoded()))
                } else {
                    screenNavigator.replace(CollectionLanding)
                }
            } else {
                screenNavigator.replace(LoginOrSignUp())
            }
        }

        suspend fun handleMobileFlow(isLoggedIn: Boolean) {
            if (!WelcomeTOSLanding.acknowledged()) {
                screenNavigator.replace(WelcomeTOSLanding)
            } else if (isLoggedIn) {
                if (currentCollection() != null) {
                    currentCollection()?.collectionId?.raw?.let { CollectionDetail(it.toSafeEncoded()) }
                        ?.let { screenNavigator.replace(it) }
                } else {
                    val firstCollection = session()?.collection2?.listCollectionsLive()?.all()?.firstOrNull()
                    if (firstCollection != null) {
                        screenNavigator.replace(CollectionDetail(firstCollection.collection.collectionId.raw.toSafeEncoded()))
                    } else {
                        screenNavigator.replace(CollectionLanding)
                    }
                }
            } else {
                screenNavigator.replace(CollectionLanding)
            }
        }

        suspend fun handleDesktopFlow(isLoggedIn: Boolean) {
            throw RuntimeException("Desktop not yet supported")
        }

        launch {
            val loggedIn = !sessionRefreshToken().isNullOrEmpty()
            val platform = Platform.current
            when (platform) {
                Platform.Web -> handleWebFlow(isLoggedIn = loggedIn)
                Platform.iOS, Platform.Android -> handleMobileFlow(isLoggedIn = loggedIn)
                Platform.Desktop -> handleDesktopFlow(isLoggedIn = loggedIn)
            }
        }
        //Could provide a splash screen here or loading page, but need to work out the details with
        //Jeff if he wants it.
        stack {}
    }
}

